.bottomNavigation {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px -4px 8px #1f00750f;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  color: #0000008a;
}
.bottomNavItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 6px 9px;
}

.bottomNavSmallText {
  font-size: 10px;
  font-family: 'Montserrat-Bold';
  text-align: center;
  margin: auto;
}

.activeNav {
  background-color: #009ece22;
  border-radius: 10px;
  min-width: 60px;
  color: #009ece !important;
}

.bottomScrollBar {
  width: 100vw;
  height: 70vh;
  // display: flex;
  text-align: center;
  padding: 10px;
  padding-top: 0px;
  overflow: auto;
  position: fixed;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px -4px 8px #1f00750f;
  bottom: -80vh;
  z-index: 1000;
  transition-duration: 0.8s;
  // transform: translate(0px, -100vh);
}

.bottomScrollBar::-webkit-scrollbar {
  display: none;
}

.scrolledUp {
  bottom: 0px !important;
}

.backDrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(128, 128, 128, 0.2);
  z-index: 1000;
}

.horizontalLine {
  width: 70px;
  height: 5px;
  border-radius: 3px;
  margin: auto;
  margin-bottom: 20px;
  background-color: #00000061;
  margin-top: 10px;
  margin-bottom: 10px;
}

.squaresC {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-flow: wrap;
  margin-bottom: 3rem;
  width: 100%;
  margin-top: 20px;
}

.square {
  margin: 9px 9px !important;
  display: flex;
  padding: 5px;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  font-family: 'Montserrat-Regular';
  background-color: #009ece12;
  border-radius: 10px;
  cursor: pointer;
  height: auto;
  font-size: 11px;
  width: 28%;
}

.activeSquare {
  color: #009ece !important;
  background-color: #0193c028;
}

.BNImage {
  width: 27%;
  max-height: 85px;
}

.textFeatureNameBN {
  margin-top: 15px;
}

.horizontalLineContainer {
  width: 100%;
  background-color: white;
  position: fixed;
}

.bottomHideOnDesktop {
  opacity: 1;
}

@media screen and (min-width: 969px) {
  .bottomHideOnDesktop {
    display: none;
  }
}

.bottomNavSpacer {
  height: 70px;
  width: 100%;
  border: 1px solid transparent;
  background-color: transparent;
  display: none;
}

@media screen and (max-width: 968px) {
  .bottomNavSpacer {
    display: block;
  }
}
