body {
  background-color: white;
}
.Login {
  @import '../../scss/bootstrap';

  margin-top: 60px;

  @include media-breakpoint-up(lg) {
    margin-top: 100px;
  }

  &__jumbo {
    width: 50%;
    background: transparent;
    max-width: 210px;
    max-height: 30vh;
    width: auto;
    // @include media-breakpoint-down(md) {
    //   max-height: 25vh;
    //   width: auto;
    // }
  }

  &__footer {
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding-bottom: 30px;

    @include media-breakpoint-down(md) {
      height: 110px;
      bottom: 10px;
    }
    text-align: center;
  }

  &__footerText {
    font-family: 'Montserrat-Regular';
    font-size: $spacer * 0.75;
    color: rgba($color: $black, $alpha: 0.6);
    line-height: 15px;
  }

  /***************Signup Form Styles********/

  &__signupHeading {
    font-family: $bold;
    font-size: $spacer * 1.5;
    line-height: 29px;
    color: $headingGrey;
    padding-left: 8px;
    @include media-breakpoint-up(md) {
      padding-left: 15%;
      text-align: center;
    }
  }

  &__photoPlaceHolder {
    width: 70px;
    height: 70px;
    background-color: $disabledGrey;
    color: $dashboardBlack;
    font-size: $spacer * 0.75;
    line-height: 15px;
    font-family: $semiBold;
    text-align: center;
    border-radius: 70px;
    padding: 18px;
    position: relative;
  }

  &__camera {
    position: absolute;
    font-size: $spacer;
    color: $headingGrey;
    right: 0;
    bottom: 0;
  }
  @media screen and (min-width: 1500px) {
    &__photoPlaceHolder {
      width: 100px;
      height: 100px;
      background-color: $disabledGrey;
      color: $dashboardBlack;
      font-size: $spacer * 0.75;
      line-height: 15px;
      font-family: $semiBold;
      text-align: center;
      border-radius: 70px;
      padding: 30px;
      position: relative;
    }

    &__Login__camera {
      position: absolute;
      font-size: $spacer;
      color: $headingGrey;
      right: 0;
      bottom: 0;
    }
  }
}

.deskWidthFORM {
  width: 65%;
}

@media screen and (max-width: 600px) {
  .deskWidthFORM {
    width: 95%;
  }
}

@media screen and (min-width: 2500px) {
  .Login__jumbo {
    max-width: 330px !important;
    max-height: 33vh !important;
  }
}

@media screen and (max-height: 750px) {
  .Login__footer {
    position: relative;
  }
  .Preloader__brand {
    font-size: 40px;
  }
}


