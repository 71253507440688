.myProgress {
  width: 100%;
  background-color: rgba(173, 173, 173, 0.65);
  border-radius: 10px;
  margin: 15px auto;
  border-radius: 100px;
}

.myBar {
  border-radius: 100px;
  background-color: var(--primary-blue);
}

.logoImage {
  width: 25%;
  margin: 20px 0px 5px 0px;
}

@media screen and (max-width: 800px) {
  .logoImage {
    width: 35%;
  }
}
