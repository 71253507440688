:root {
  --primary-blue: rgba(38, 153, 251, 1);
  --lighter-blue: rgba(127, 196, 253, 1);
  --lightest-blue: rgba(188, 224, 253, 1);
  --superlight-blue: rgba(241, 249, 255, 1);
  --small:10px;
  --medium:14px;
  --large:24px;
  --head:16px
}
:focus {
  outline: 0 !important;
}

.Sidebar{
  position: fixed;
  height: 100vh;
  width: 20vw;
  top: 0;
  left: 0;
  background: white;
  isolation: isolate;
}

.swal2-header {
  font-family: 'Montserrat';
}
.swal2-content {
  font-family: 'Montserrat-Regular';
  font-size: 16px !important;
  line-height: 24px !important;
}



@media screen and (max-width: 450vh) {

  .plyr video {
    height: 30vh;
    max-height: inherit;
  }
}

@media screen and (max-width: 1200px) {
  :root{
    --small:11px;
    --medium:13px;
    --large:20px;
  }
}

@media screen and (max-width: 500px) {
  :root{
    --small:10px;
    --medium:12px;
    --large:20px;
  }
}

// .rootContainer {
//   @import '../scss/bootstrap';

//   @include media-breakpoint-up(lg) {
//     width: 60%;
//   }
// }
