.LoginDetailsSkeleton {
  @import '../../../scss/bootstrap';
  &__text {
    color: $headingGrey;
    font-size: $spacer;
    line-height: 36px;
    font-family: $medium;
    @include media-breakpoint-up(md) {
      font-size: 24px;
      text-align: center;
    }
  }

  &__phoneNoOnly {
    @include media-breakpoint-down(md) {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  &__phoneNoInput {
    @include media-breakpoint-up(md) {
      width: 40%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__img {
    width: 120px;
    height: 125px;

    @include media-breakpoint-up(sm) {
      width: 150px;
      height: auto;
    }
  }
}

.iti {
  width: 100%;
}

.iti > input::placeholder {
  font-family: 'Montserrat-SemiBold';
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  line-height: 24px;
}

.iti > input {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.54);
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Montserrat-SemiBold';
  font-size: 14px;
  height: 32px;
  line-height: 24px;
  width: 100%;
}

.deskWidth {
  width: 20%;
}

.loginComponentsContainer {
  position: relative;
  min-height: 100%;
}

@media screen and (max-width: 600px) {
  .deskWidth {
    width: 25%;
  }
}
// new css
@media screen and (min-width: 1440px) {
  .Login__signupHeading {
    font-size: 40px !important;
    padding-left: 19%;
  }
  .deskWidthFORM {
    width: 80%;
  }
  .verifyButton {
    height: 60px !important;
    font-size: 28px;
  }
  .formInput {
    padding: 25px 25px;
    font-size: 22px !important;
  }
  .LoginDetailsSkeleton__text {
    font-size: 30px;
    // margin-top: 5% !important;
    margin: auto !important;
    width: 40% !important;
    text-align: left;
  }
  .LoginDetailsSkeleton__img {
    width: 25%;
    min-width: 210px;
  }
  .btn-loginPrimary {
    height: 50px !important;
    font-size: 28px;
  }
  .iti > input::placeholder {
    font-size: 18px !important;
  }
  .iti > input {
    height: 50px !important;
    font-size: 18px !important;
  }
  .PhoneNo__show {
    padding: 0.7rem;
  }
  .errorTxt {
    font-size: 18px !important;
  }
}
