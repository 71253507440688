$black: #000;
$white: #fff;
$grey: rgba(0, 0, 0, 0.6);
$headingGrey: rgba(0, 0, 0, 0.87);
$disabledGrey: rgba(0, 0, 0, 0.38);
$lightestGrey: rgba(0, 0, 0, 0.06);
$lightGrey: rgba(0, 0, 0, 0.54);
$primaryBlue: rgba(38, 153, 251, 1);
$onlineGreen: #00ff00;
$yellow: #ffff00;
$dirtyYellow: rgba(251, 226, 68, 1);
$lighterGrey: rgba(236, 236, 236, 1);
$chatDotsGrey: rgba(175, 175, 175, 1);
$disabledBlue: #f1f9ff;
$dashboardBackground: rgba(249, 253, 255, 1);
$dashboardBlack: rgba(22, 22, 22, 1);
$strongRed: rgba(255, 0, 0, 0.87);
$dashboardGrey: rgba(112, 112, 112, 1);
$dashboardGreyLight: rgba(112, 112, 112, 0.2);
$lighterBlue: rgba(127, 196, 253, 1);
$lightestBlue: rgba(188, 224, 253, 1);
$homeworkBlue: rgba(0, 102, 255, 0.87);
$dialerBlack: rgba(33, 33, 33, 1);
$feesGreen: rgba(58, 255, 0, 0.87);

/*******************FONTS*********************/

$regular: 'Montserrat-Regular';
$medium: 'Montserrat-Medium';
$semiBold: 'Montserrat-SemiBold';
$bold: 'Montserrat-Bold';
$light: 'Montserrat-Light';
$extraBold: 'Montserrat-ExtraBold';
