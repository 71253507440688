.OTPInput {
  @import '../../../scss/bootstrap';

  &__heading {
    margin-top: $spacer * 5;
    color: $headingGrey;
    font-family: 'Montserrat-Regular';
    font-size: $spacer * 0.875;

    @include media-breakpoint-up(sm) {
      margin-top: $spacer * 8;
      font-size: $spacer * 1.5;
    }
  }

  &__mobile {
    color: $lightGrey;
    font-family: 'Montserrat-Regular';
    font-size: $spacer;

    @include media-breakpoint-up(sm) {
      font-size: $spacer * 1.75;
    }
  }

  &__resend {
    color: var(--primary-blue);
    font-family: 'Montserrat-Regular';
    font-size: $spacer * 0.75;
    letter-spacing: 0.3em;

    @include media-breakpoint-up(sm) {
      font-size: $spacer;
    }
  }

  &__timer {
    color: $lightGrey;
    font-family: 'Montserrat-Medium';
    font-size: $spacer * 0.75;
    letter-spacing: 0.2em;

    @include media-breakpoint-up(sm) {
      font-size: $spacer;
    }
  }

  .btn-custom {
    background-color: var(--primary-blue);
    color: $white;
    font-family: 'Montserrat-Medium';
    border-radius: $spacer * 1.5;
    padding-left: $spacer * 2;
    padding-right: $spacer * 2;
    margin-bottom: $spacer;
  }
}
