.Scrollable {
  @import '../../../scss/bootstrap';

  &__card {
    background-color: #fff;
    min-width: 100%;
    min-height: 200px;
    display: flex;
    overflow-x: auto;
  }

  &__cardContent {
    min-width: 150px;
    margin: 5px;

    @include media-breakpoint-up(md) {
      min-width: 200px;
    }
  }

  &__aspectCardContent {
    min-width: 200px;
    height: 113px;
    border-radius: 10px;

    @include media-breakpoint-up(md) {
      min-width: 320px;
      min-height: 181px;
    }
  }

  &__arrowIconLeft {
    height: 113px;
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 200;
    background-color: gray;
    color: white;
    opacity: 0;
    transition-duration: 0.3s;
    margin-top: 4px;

    @include media-breakpoint-up(md) {
      height: 181px;
    }
  }

  &__arrowIconRight {
    height: 113px;
    display: flex;
    align-items: center;
    position: absolute;
    margin-top: 4px;
    background-color: gray;
    z-index: 200;
    right: 0px;
    color: white;
    transition-duration: 0.3s;

    @include media-breakpoint-up(md) {
      height: 181px;
    }
  }

  &__arrowIconLeftBig {
    height: 177px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 200;
    background-color: gray;
    color: white;
    opacity: 0;
    transition-duration: 0.3s;
    margin-top: 4px;

    @include media-breakpoint-up(md) {
      height: 283px;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__arrowIconRightBig {
    height: 177px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-top: 4px;
    background-color: gray;
    z-index: 200;
    right: 0px;
    color: white;
    transition-duration: 0.3s;

    @include media-breakpoint-up(md) {
      height: 283px;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__aspectCardBig {
    min-width: 315px;
    aspect-ratio: 16/9;
    height: 177px;
    // border-radius: 10px;

    @include media-breakpoint-up(md) {
      min-width: 504px;
      min-height: 283px;
    }
  }

  &__card::-webkit-scrollbar {
    display: none;
  }

  &__image {
    height: 60px;
    width: 60px;
    border-radius: 50px;
  }

  &__cardHeading {
    font-family: 'Montserrat-Regular';
    font-weight: 700;
    color: $lightGrey;

    @include media-breakpoint-up(md) {
      font-size: $spacer * 1.5;
    }
  }

  &__cardSubHeading {
    font-family: 'Montserrat-Regular';
    font-size: $spacer * 0.75;
    color: $lightGrey;

    @include media-breakpoint-up(md) {
      font-size: $spacer;
    }
  }

  &__cardFlex {
    flex-direction: row-reverse;
    min-height: 113px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  /****************************Course Cards**********/

  &__courseCardContent {
    width: 220px;
    margin: 5px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    min-height: fit-content;
    height: 258px;
    margin: 20px 5px;
  }

  &__courseCard {
    background-image: linear-gradient(#f8f8f8, #9b9b9b);
    min-width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
  }

  &__courseCard::-webkit-scrollbar {
    display: none;
  }

  &__courseImage {
    // max-width: 100%;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    height: 150px;
    object-fit: contain;
  }

  &__subHeadTextUnderHeading {
    font-family: $medium;
    color: black;
    display: 'flex';
    flex-direction: column;
  }

  &__subHeadTextUnderHeadingDyn {
    font-family: $medium;
    color: black;
    display: 'flex';
    flex-direction: column;
    width: 98%;
    margin: auto;

    @include media-breakpoint-down(sm) {
      width: 95%;
    }
  }

  &__coursesInitialHeading {
    position: relative;
    top: 50%;
    color: $headingGrey;
    font-family: $bold;
    margin: 20px 0px;
  }

  &__blurCourseImage {
    filter: blur(3px);
  }

  &__viewAllButtonForCards {
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;
    background-color: transparent;
    border: none;
    font-size: 12px;
    font-family: $medium;
    margin: 20px 0px;
  }

  &__subHeadTextUnderHeading {
    position: absolute !important;
    left: 38px !important;
    display: flex;
    width: 93%;
    justify-content: space-between;
    align-items: flex-start;

    @include media-breakpoint-down(sm) {
      left: 15px !important;
      width: 95%;
    }
  }

  &__smallText {
    color: $white;
    font-size: $spacer * 0.625;
    font-family: $regular;
    line-height: 13px;
  }

  &__noReviewsText {
    font-size: 12px;
    font-family: 'Montserrat-Regular';
    margin-bottom: 3px;
    padding-left: 5px;
    text-align: left;
    color: white;
  }

  &__negativeMargin {
    margin-bottom: -5px !important;
  }

  &__negativeMargin2 {
    margin-bottom: 3px !important;
  }

  &__courseCardHeading {
    font-size: $spacer * 0.75;
    font-family: $semiBold;
    color: $headingGrey;
    line-height: 15px;
    text-align: left;

    @include media-breakpoint-up(sm) {
      font-size: $spacer * 0.85;
    }
  }

  &__courseCardSubHeading {
    font-size: $spacer * 0.625;
    font-family: $regular;
    color: $lightGrey;
    line-height: 13px;
    text-align: left;
  }

  &__emoji {
    width: $spacer !important;
    height: $spacer !important;
    color: $dirtyYellow;

    @include media-breakpoint-down(sm) {
      width: 15px !important;
      height: 15px !important;
    }
  }

  &__bestSeller {
    background-color: $dirtyYellow;
    text-align: center;
    border-radius: 2px;
    font-size: $spacer * 0.4;
    line-height: 11px;
    color: $headingGrey;
    font-family: $bold;
    width: fit-content !important;

    @include media-breakpoint-up(sm) {
      width: 20%;
    }
  }

  &__viewAll {
    font-size: $spacer * 1.5;
    font-family: $medium;
    letter-spacing: 2px;
    line-height: 28px;
  }

  /****************Fee Cards*****************/

  &__recentlyUsed {
    font-size: $spacer * 0.875;
    line-height: 20px;
    color: $headingGrey;
    font-family: $semiBold;
  }

  &__feeCardContent {
    min-width: 124px;
    margin: 5px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
  }

  &__feecardHeading {
    font-size: $spacer * 0.625;
    line-height: 15px;
    color: $dashboardBlack;
    font-family: $semiBold;

    @include media-breakpoint-up(sm) {
      font-size: $spacer * 0.825;
    }
  }

  &__error {
    font-size: $spacer * 0.875;
    font-family: $semiBold;
  }

  /*****************************Analysis Cards**********************************/

  &__analysisHeading {
    border-left: 1px solid $primaryBlue;
    border-bottom: 1px solid $primaryBlue;
    border-top: 1px solid $primaryBlue;
    height: 20%;
    border-radius: 10px 0 0 10px;
    font-size: $spacer * 0.625;
    font-family: $medium;
  }

  &__analysisValue {
    border: 1px solid $primaryBlue;
    height: 30%;
    width: 30%;
    border-radius: 5px;
    font-family: $regular;
    color: $lightGrey;
    font-size: $spacer * 1.5;
  }

  //////////////live classes//////////////

  &__liveClassesCard {
    min-width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
  }

  &__liveClassesCard::-webkit-scrollbar {
    display: none;
  }

  /////////////dynamic Cards///////////////

  &__dynamicCard {
    min-width: 35%;
    width: 35%;
    height: fit-content;
    // min-width: 585px;
    overflow: hidden;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
    background-size: cover;
    background-position: center;
    cursor: pointer;

    // @include media-breakpoint-down(sm) {
    //   width: 288px;
    //   height: 162px;
    // }
  }

  &__imageAd {
    width: 100%;
    // width: 587px;
    height: auto;
    // @include media-breakpoint-down(sm) {
    //   width: 290px;
    //   height: 163px;
    // }
  }

  &__heading {
    font-size: x-large;
    font-weight: 200;
    @include media-breakpoint-down(md) {
      font-size: $spacer * 0.875;
      font-family: $semiBold;
      line-height: 20px;
    }
  }

  &__cardImage {
    text-align: left;
    padding-right: 40px;
  }
}
.smallTextUnderHeading {
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  width: 60%;
  margin-bottom: 18px;
}
@media screen and (min-width: 800px) {
  // .course-card {
  //   margin-right: 40px;
  // }
}
@media screen and (max-width: 335px) {
  .smallTextUnderHeading {
    font-size: 10px;
  }
  .Scrollable__coursesInitialHeading {
    font-size: 16px;
  }
}

.scheduleCardDashboard {
  background-color: #ff000012;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  width: 100%;
  min-width: 300px;
  min-height: 200px;
  margin: 20px 0px;
  overflow: hidden;
  padding: 20px;
}

.scheduleCardLeft {
  flex-grow: 3;
}

.scheduleCardRight {
  flex-grow: 1;
}

.strictInline {
  display: inline !important;
}

.redTag {
  background-color: red;
  display: inline !important;
  border-radius: 5px;
  padding: 3px 17px;
  color: white;
}

.scheduleCardHeading {
  font-family: 'Montserrat-Medium';
  font-size: 14px;
  margin-bottom: 6px;
}

.scheduleCardText {
  font-family: 'Montserrat-Regular';
  font-size: 12px;
  margin: 1px 0px;
}

.scheduleCardSmallText {
  font-family: 'Montserrat-Regular';
  font-size: 10px;
  margin: 1px 0px;
  width: 80%;
}

.teacherImage {
  width: 83px;
  position: relative;
  left: 20px;
}

.startNowButton {
  outline: none;
  border: 1px solid black;
  background-color: transparent;
  border-radius: 5px;
  color: black;
  font-family: 'Montserrat-Medium';
  cursor: pointer;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.w-85 {
  width: 85%;
}

.headingText {
  font-family: 'Montserrat-Bold';
  font-size: 20px;
}

.plainText {
  font-family: 'Montserrat-Regular';
  font-size: 12px;
}

.boldText {
  font-family: 'Montserrat-Medium';
  font-size: 12px;
}

.aspectCardsInnerContainer {
  display: flex;
  overflow: scroll !important;
  width: 100%;
  margin: auto;
}

.aspectCardsInnerContainer::-webkit-scrollbar {
  display: none !important;
}

.desktopDisplayforCards {
  width: 99%;
  margin-left: 10px;
  margin-right: 0px;
}

.deskDisplayLC {
  width: 93%;
}

.appearAfter1Second {
  animation: appear 0s 3s forwards;
  -moz-animation: appear 0s 3s forwards;
  -webkit-animation: appear 0s 3s forwards;
  -o-animation: appear 0s 3s forwards;
  visibility: hidden;
  opacity: 0;
  height: 0px;
  width: 0px;
  animation-duration: 0.5s;
  transition-duration: 0.5s;
}
@keyframes appear {
  to {
    visibility: visible;
    opacity: 1;
    width: 100%;
    height: auto;
  }
}
@-webkit-keyframes appear {
  to {
    opacity: 1;
    visibility: visible;
    width: 100%;
    height: auto;
  }
}

.dissapearAfter1Second {
  -moz-animation: dissapear 0s ease-in 3s forwards;
  -webkit-animation: dissapear 0s ease-in 3s forwards;
  -o-animation: dissapear 0s ease-in 3s forwards;
  animation: dissapear 0s ease-in 3s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  width: 100%;
  height: auto;
}
@keyframes dissapear {
  to {
    width: 0px !important;
    height: 0px !important;
    visibility: hidden;
    opacity: 0;
  }
}
@-webkit-keyframes dissapear {
  to {
    width: 0px;
    height: 0px;
    opacity: 0;
    visibility: hidden;
  }
}

@media screen and (max-width: 600px) {
  .aspectCardsInnerContainer {
    width: 100%;
  }
  .deskDisplayLC {
    width: 95%;
  }
  .desktopDisplayforCards {
    width: 97%;
  }
  .Scrollable__imageAd {
    width: 290px;
    height: 163px;
  }
  .Scrollable__dynamicCard {
    min-width: 288px;
    height: 162px;
  }
}

@media screen and (max-width: 770px) {
  .Scrollable__aspectCardBig {
    width: 100px;
  }
  .scheduleCardDashboard {
    width: 95%;
  }
  .w-85 {
    width: 95%;
  }
}

////////////// very large screens
@media screen and (min-width: 1440px) {
  .Scrollable__aspectCardBig {
    min-width: 670px;
    min-height: 378px;
  }
  .Scrollable__arrowIconLeftBig,
  .Scrollable__arrowIconRightBig {
    height: 378px;
  }
  .aspectCardsInnerContainer {
    margin-bottom: 14px !important;
  }
  .Scrollable__aspectCardContent {
    min-width: 485px;
    min-height: 274px;
  }
  .Scrollable__arrowIconLeft,
  .Scrollable__arrowIconRight {
    height: 274px;
  }
  .Scrollable__coursesInitialHeading {
    font-size: 28px;
  }
  .smallTextUnderHeading {
    font-size: 18px;
  }
}

// 16:9.aspectCards

//disable scrollbar
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}
.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin-bottom: 50px;
}

//icon css
.hoverClass {
  color: white;
  border-radius: 100px;
}

.hoverClass:hover {
  opacity: 0.7 !important;
}
@media (min-width: 768px) {
  .carousel_image {
    max-width: 70vw;
  }
  /* Adjust styles for desktop screens */
  .header_img {
    min-width: 80vw;
    height: 350px;
    /* You can adjust the percentage as needed */
  }
}
