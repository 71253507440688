.Tests {
  @import '../../scss/bootstrap';

  &__scrollableCard {
    min-width: 100%;
    min-height: 103px;
    display: flex;
    overflow-x: auto;
  }

  &__scrollableCard::-webkit-scrollbar {
    display: none;
  }

  &__scrollableCard > div {
    min-width: 276px;
    margin: 5px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
    border: 1px solid transparent;
    border-radius: 10px;
  }

  &__headingContainer {
    font-family: $medium;
    color: black;
    display: 'flex';
    flex-direction: column;
    // padding-left: 20px;
  }

  &__viewAllButtonForCards {
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;
    background-color: transparent;
    border: none;
    font-size: 12px;
    font-family: $medium;
    margin: 20px 0px;
  }

  &__initialHeading {
    position: relative;
    top: 50%;
    color: $headingGrey;
    font-family: $bold;
    margin: 20px 0px;
  }

  &__smallTextUnderHeading {
    font-size: 12px;
    font-family: 'Montserrat-Regular';
    width: 60%;
    margin-bottom: 18px;
  }

  &__scrollableCardHeading {
    font-size: $spacer * 0.875;
    color: $headingGrey;
    line-height: $spacer * 1.25;
    font-family: 'Montserrat-SemiBold';
    color: $homeworkBlue;
  }

  &__scrollableCardText {
    font-size: $spacer * 0.75;
    font-family: 'Montserrat-Regular';
    color: $dashboardBlack;
    line-height: 15px;
  }

  &__Counter {
    font-family: $semiBold;
    color: $headingGrey;
    letter-spacing: 0.43px;
    line-height: $spacer * 1.75;
    font-size: $spacer * 0.875;
  }
}

.container {
  width: 100%;
  /* margin: 2px; */
}
.divContainer {
  width: 100% !important;
  /* background-color: blue; */
  display: block;
  margin: auto;
}
.tabs {
  margin-top: 70px;
  // position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid red; */
}
.nav-item {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.noMatch {
  width: 100%;
  height: 100%;
  font-family: 'Montserrat-Medium';
  margin: auto;
  text-align: center;
}
.tablc {
  overflow: auto;
}
.tablc::-webkit-scrollbar {
  display: none;
}

.livetestImg {
  text-align: center;
}

.secContainer {
  width: 75% !important;
  margin: 7px auto !important;
}

@media screen and (max-width: 600px) {
  .livetestImg {
    text-align: left;
  }
  .secContainer {
    width: 95% !important;
  }
  .OnlineTestHeadingContainer {
    width: 95%;
    margin: auto;
  }
}

.Tests__scrollableCard {
  padding-left: 3% !important;
}

.OnlineTestHeadingContainer {
  width: 93% !important;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .Tests__scrollableCard {
    padding-left: 0px !important;
  }
}

@media screen and (min-width: 1440px) {
  .Tests__initialHeading {
    font-size: 28px;
  }
  .Tests__smallTextUnderHeading {
    font-size: 18px;
  }
  .Tests__scrollableCardHeading span {
    font-size: 19px;
  }
  .Tests__scrollableCardHeading {
    font-size: 19px;
  }
  .Tests__Counter {
    font-size: 19px;
  }
  .Tests__scrollableCardText {
    font-size: 16px;
  }
  .Tests__scrollableCard > div {
    min-width: 400px;
  }
  .Tests__viewAllButtonForCards {
    font-size: 16px;
  }
}
