// @font-face {
//   font-family: "Nunito-Regular";
//   src: url("/assets/fonts/Nunito/Nunito-Regular.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Nunito-Light";
//   src: url("/assets/fonts/Nunito/Nunito-Light.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Nunito-Bold";
//   src: url("/assets/fonts/Nunito/Nunito-Bold.ttf") format("truetype");
// }

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../assets/fonts/Montserrat/Montserrat-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url('../assets/fonts/Montserrat/Montserrat-Light.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../assets/fonts/Montserrat/Montserrat-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('../assets/fonts/Montserrat/Montserrat-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('../assets/fonts/Montserrat/Montserrat-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf') format('woff');
}

@font-face {
  font-family: 'MartelSans-Regular';
  src: url('../assets/fonts/Martel_Sans/MartelSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MartelSans-Bold';
  src: url('/assets/fonts/Martel_Sans/MartelSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'MartelSans-Light';
  src: url('/assets/fonts/Martel_Sans/MartelSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Caramel-Regular';
  src: url('/assets/fonts/Montserrat/Caramel-Regular.ttf') format('truetype');
}

// @font-face {
//   font-family: "OpenSans-Regular";
//   src: url("/assets/fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype");
// }

// @font-face {
//   font-family: "OpenSans-Bold";
//   src: url("/assets/fonts/Open_Sans/OpenSans-Bold.ttf") format("truetype");
// }

// @font-face {
//   font-family: "OpenSans-Light";
//   src: url("/assets/fonts/Open_Sans/OpenSans-Light.ttf") format("truetype");
// }
