.Dummy {
  @import '../../scss/bootstrap';

  &__coachingName {
    font-size: $spacer * 1.5; // 1rem = 16px
    line-height: 29px;
    font-family: $extraBold;
    color: $white;

    @include media-breakpoint-up(sm) {
      font-size: $spacer * 2.25;
      margin-bottom: 0.875rem;
    }
  }

  &__tagline {
    color: $white;
    font-family: $regular;
    line-height: 19px;

    @include media-breakpoint-up(sm) {
      font-size: $spacer * 1.25;
    }
    @include media-breakpoint-up(md) {
      margin-top: 25px;
    }
  }

  &__aboutus {
    font-family: $bold;
    font-size: $spacer * 1.2;
    line-height: 24px;
    color: #000;
  }

  &__aboutData {
    font-family: $regular;
    font-size: $spacer * 0.875;
    line-height: 18px;
  }

  &__connect {
    font-family: $medium;
    font-size: $spacer * 0.875;
    line-height: 20px;
  }

  &__socialLinks {
    width: 36px;
    height: 36px;
  }

  &__joinUs {
    font-family: $bold;
    line-height: 20px;
    font-size: $spacer * 0.875;
    color: $headingGrey;
  }

  &__joinDetails {
    font-family: $medium;
    line-height: 15px;
    color: $dashboardBlack;
    font-size: $spacer * 0.75;
  }

  &__contactDetails {
    font-family: $medium;
    line-height: 15px;
    color: $dashboardBlack;
    font-size: $spacer * 0.75;

    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
  }

  &__contactSmall {
    font-family: $regular;
    line-height: 13px;
    color: $dashboardBlack;
    font-size: $spacer * 0.625;

    @include media-breakpoint-up(md) {
      font-size: 12px;
      margin-top: 5px;
    }
  }

  &__joinSmall {
    font-family: $regular;
    line-height: 13px;
    color: $dashboardBlack;
    font-size: $spacer * 0.625;
  }
}
.testimonialVideoTag {
  width: 450px !important;
  height: auto !important;
}
.colorForBack {
  background-color: var(--primary-blue);
}
@media screen and (max-width: 800px) {
  .testimonialVideoTag {
    width: 300px !important;
    height: auto !important;
  }
}

@media screen and (max-width: 300px) {
  .testimonialVideoTag {
    width: 220px !important;
    height: auto !important;
  }
}

.Dashboard_image {
  width: 92px;
  padding-right: 20px;
}

@media screen and (max-width: 600px) {
  .Dashboard_image {
    padding-right: 0px;
    width: 73px;
  }
}

/// For very large screens ///
@media screen and (min-width: 1440px) {
  .Dummy__coachingName {
    font-size: 64px !important;
    font-family: 'Montserrat-Bold';
    line-height: 70px;
  }
  .Dummy__tagline {
    font-size: 40px;
    line-height: 44px;
    margin-top: 48px;
    margin-bottom: 50px !important;
  }
  .Dummy__socialLinks {
    width: 50px;
    height: 50px;
  }
  .Dummy__aboutus {
    font-size: 28px;
  }
  .Dummy__aboutData {
    font-size: 18px;
    line-height: 23px;
  }
  .Dummy__joinDetails,
  .Dummy__connect {
    font-size: 18px !important;
  }
  .Dummy__joinSmall {
    margin-top: 15px !important;
    font-size: 16px !important;
  }
  .Dummy__joinUs {
    font-size: 28px !important;
    margin-bottom: 45px !important;
  }
  .Dummy__contactDetails {
    font-size: 19px;
  }
  .Dummy__contactSmall {
    font-size: 16px;
  }

  // new css

  .addmissionButton {
    width: 20%;
    height: 50px;
    font-size: 20px;
  }
  .shareButton {
    width: 15%;
    height: 45px;
    font-size: 18px;
  }
  .Dashboard_image {
    width: 25%;
  }
  .shareImage {
    width: 25%;
  }
  .colorForBack {
    width: 15%;
    height: 50px;
    font-size: 18px;
  }
}
