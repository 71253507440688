@import '~bootstrap/scss/bootstrap';
@import './scss/bootstrap';

// overrides bootstraps default tabbing style
html,
body {
  overflow-x: hidden !important;
  background-color: hsl(var(--hue), 10%, 90%);
}

.nav-tabs .nav-link.active {
  color: var(--primary-blue);
  border-bottom: 3px solid var(--primary-blue);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  font-family: $regular;
}

.nav-link {
  color: $black;
  font-family: $regular;
}

.iti__country-list {
  position: relative;
  z-index: 10 !important;
}

.NoDataFound {
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

/****************************************Tab Style ENDS*****************/