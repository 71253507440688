.PhoneNo {
  @import '../../../scss/bootstrap';

  &__text {
    color: $headingGrey;
    font-size: 14px;
    @include media-breakpoint-up(md) {
      font-size: 24px;
    }
  }

  &__engText {
    font-family: 'Montserrat-Regular';
  }

  &__hinText {
    font-family: 'MartelSans-Regular';
  }

  &__passText {
    font-size: $spacer * 1.25;
    font-family: 'Montserrat-Medium';
    color: $headingGrey;

    @include media-breakpoint-up(md) {
      font-size: $spacer * 2;
    }
  }

  &__input {
    width: 90%;
    @include media-breakpoint-up(md) {
      width: 75%;
    }
  }

  &__show {
    position: absolute;
    top: 1%;
    right: 1%;
    z-index: 10;
  }

  &__forgot {
    color: var(--primary-blue);
    font-family: 'Montserrat-Regular';
    font-size: $spacer * 0.75;
    letter-spacing: 0.4px;

    @include media-breakpoint-up(md) {
      font-size: $spacer;
    }
  }
}

.iti > input::placeholder {
  font-family: 'Montserrat-SemiBold';
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  line-height: 24px;
}

.iti > input {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.54);
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Montserrat-SemiBold';
  font-size: 14px;
  height: 32px;
  line-height: 24px;
}
