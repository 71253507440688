.rangeStyle > input[type='range'] {
  width: 100%;
  margin: 8.6px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
.rangeStyle > input[type='range']:focus {
  outline: none;
}
.rangeStyle > input[type='range']::-webkit-slider-runnable-track {
  background: var(--primary-blue);
  border: 0.2px solid #010101;
  border-radius: 1.3px;
  width: 100%;
  height: 7.8px;
  cursor: pointer;
}
.rangeStyle > input[type='range']::-webkit-slider-thumb {
  margin-top: -8.8px;
  width: 20px;
  height: 20px;
  background: #f2ffff;
  border: 1.1px solid #000000;
  border-radius: 20px;
  cursor: pointer;
  -webkit-appearance: none;
}
.rangeStyle > input[type='range']:focus::-webkit-slider-runnable-track {
  background: var(--lighter-blue);
}
.rangeStyle > input[type='range']::-moz-range-track {
  background: var(--primary-blue);
  border: 0.2px solid #010101;
  border-radius: 1.3px;
  width: 100%;
  height: 7.8px;
  cursor: pointer;
}
.rangeStyle > input[type='range']::-moz-range-thumb {
  width: 16px;
  height: 25px;
  background: #f2ffff;
  border: 1.1px solid #000000;
  border-radius: 3px;
  cursor: pointer;
}
.rangeStyle > input[type='range']::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 18.1px 0;
  color: transparent;
  width: 100%;
  height: 7.8px;
  cursor: pointer;
}
.rangeStyle > input[type='range']::-ms-fill-lower {
  background: #a231b9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
}
.rangeStyle > input[type='range']::-ms-fill-upper {
  background: var(--primary-blue);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
}
.rangeStyle > input[type='range']::-ms-thumb {
  width: 16px;
  height: 25px;
  background: #f2ffff;
  border: 1.1px solid #000000;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
.rangeStyle > input[type='range']:focus::-ms-fill-lower {
  background: var(--primary-blue);
}
.rangeStyle > input[type='range']:focus::-ms-fill-upper {
  background: var(--lighter-blue);
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  .rangeStyle > input[type='range'] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}
