.Batches {
  @import '../../../scss/bootstrap';

  height: 70vh;
  overflow-y: scroll;

  &__totalBatches:after {
    content: '';
    background-color: $disabledGrey;
    position: absolute;
    width: 2px;
    height: 100%;
    top: 0;
    right: 0;
    display: block;
  }
}

// .Batches::-webkit-scrollbar {
//   display: none;
// }
.btn_main {
  min-width: 80%;
}
