.FormTemplate {
  @import '../../../scss/bootstrap';

  &__input {
    width: 80%;

    @include media-breakpoint-up(md) {
      width: 50%;
    }
  }

  &__select {
    width: 80%;
    height: 40px;
    border-radius: 5px;
    background-color: $white;
    font-family: 'Montserrat-Regular';
    padding: 6px 12px;
    color: $headingGrey;
    font-weight: 900;
    opacity: 0.7;

    @include media-breakpoint-up(md) {
      width: 50%;
    }
  }

  &__hasError {
    box-shadow: 0px 0px 10px 5px rgba(220, 53, 69, 1);
  }
}
.reactSelectClass {
  @import '../../../scss/bootstrap';

  margin: 24px 0px;
  position: 'fixed';
  font-family: 'Montserrat-Regular';
  color: $headingGrey;
}

.City {
  z-index: 5;
}
.State {
  z-index: 6;
}
